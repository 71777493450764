window.footerHandler = () => {
  document.addEventListener('DOMContentLoaded', () => {
    changeLocationEvents();
    footerLinkLineEvents();
    newsletterEvent();
    sitemapEvents();
  });

  window.addEventListener('resize', () => {
    footerLinkLineEvents();
  });

  const footerTabAll = document.querySelectorAll('.footer-links-box');

  const activeClass = 'active';

  const hideAllFooterTabs = (targetFooterTab) => {
    footerTabAll.forEach(el => targetFooterTab !== el && el.classList.remove(activeClass));
  }

  footerTabAll.forEach(footerTab => {
    footerTab.addEventListener('click', () => {
      hideAllFooterTabs(footerTab);
      footerTab.classList.toggle(activeClass);
    });
  })


  const changeLocationEvents = () => {
    const buttonShowModal = document.querySelector('.jsFooterChangeLocation');
    const modal = document.querySelector('#modal-change-region');
    const html = document.querySelector('html');

    // TODO: temporary solution, fix with new navigation
    if(!buttonShowModal) return;

    buttonShowModal.addEventListener('click', () => {
      modal.classList.add(activeClass);
      html.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
    });
  };

  const footerLinkLineEvents = () => {
    const linkContainer = document.querySelector('.jsFooterLinksContainer');
    const links = linkContainer.querySelectorAll('.jsFooterLinks');

    links.forEach((link, index) => {
      const prevLinkPos = links[(index === 0 ? 1 : index) - 1].offsetLeft;
      const nextLink = links[(index === links.length - 1 ? index - 1 : index) + 1];
      const currentLinkPos = link.offsetLeft;
      const isOneElemInRow = link.offsetWidth + nextLink.offsetWidth >= linkContainer.offsetWidth;

      // hide line if the element is last in row
      prevLinkPos > currentLinkPos ? links[index - 1].classList.add('hidden') : link.classList.remove('hidden');

      // hide line if row contains only one link
      isOneElemInRow ? link.classList.add('hidden') : link.classList.remove('hidden')
    });
  };

  const inputEvents = (input) => {
    const inputType = input.getAttribute('type');

    // Input validating
    input.addEventListener('input', (e) => {
      if (inputType === 'email') {
        if (validateEmail(e.target.value)) {
          input.classList.remove('wrong')
          input.setAttribute('data-validated', 'true')
        } else {
          input.classList.add('wrong')
          input.setAttribute('data-validated', 'false')
        }
      } else if (inputType === 'text') {
        if (e.target.value.length === 0) {
          input.classList.add('wrong')
          input.setAttribute('data-validated', 'false')
        } else {
          input.classList.remove('wrong')
          input.setAttribute('data-validated', 'true')
        }
      }
    });

    // When input is editing
    input.addEventListener('focus', () => {
      input.classList.add('active')
    });

    // When input is not editing
    input.addEventListener('focusout', () => {
      input.classList.remove('active')
    });

  }

  const newsletterEvent = () => {
    const label = document.querySelector('.jsNewsletter');
    const input = label.querySelector('.jsNewsletterInput');
    const submit = label.querySelector('.jsNewsletterSubmit');

    inputEvents(input);

    // On submit newsletter
    submit.addEventListener('click', e => {
      e.preventDefault();
      if (validateEmail(input.value)) modalEvents(input.value)
    });

    // click "enter"
    input.addEventListener('keyup', e => {
      e.preventDefault();
      const clickedEnter = e.keyCode === 13;

      if (validateEmail(input.value) && clickedEnter) modalEvents(input.value)
    });
  }

  const sitemapEvents = () => {
    const sitemapBtns = document.querySelectorAll('.jsFooterSitemapBtn');
    const sitemapContainer = document.querySelector('.jsFooterSitemap');

    sitemapBtns.forEach(el => el.addEventListener('click', (el) => {
      const sitemapBtn = el.target;
      const gap = window.innerWidth > window.gridBreakpoints.sm ? -142 : -118; // 142 = 80 (nav) + 62 (bar); 118 = 74 + 44
      sitemapBtn.classList.toggle('active');

      if (sitemapBtn.classList.contains('active')) {
        slideDown(sitemapContainer, 750);
        scrollToDest(sitemapContainer, gap, 750);
      } else {
        slideUp(sitemapContainer, 750);
        scrollToDest(sitemapContainer, 0, 750);
      }
    }));
  }

  const modalEvents = (email) => {
    const modal = document.querySelector('.jsModalNewsletter');
    const html = document.querySelector('html');
    const emailInput = document.querySelector("div[data-form-id='cd62db05-91dc-ee11-904c-6045bd0cc78a'] input[type='email']");
    if(emailInput) {
      emailInput.value = email;
    }
    modal.classList.add('active');
    document.body.style.overflow = 'hidden';
    html.style.overflow = 'hidden';
  }
}
